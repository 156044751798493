import dayjs from 'dayjs';
import get from 'lodash/get';

/**
 * Get the nest step of a time intervals for the current time
 * ie: if current time is 4:20 it will return 4:30 on a 15 minutes interval 
 */
export function getNextTimeInterval(intervalMinsIncrement: number): string {
    const roundedUp = Math.ceil(dayjs().minute() / intervalMinsIncrement) * intervalMinsIncrement;
    return dayjs().minute(roundedUp).second(0).toISOString();
}

/** 
 * return the time difference in minutes between to times
 */
export function timeDiff(startTime: string, endTime: string): number {
    return dayjs(endTime).diff(startTime, 'minutes');
}

/** 
 * return the minimun time gap in minutes or 30 minutes if time gap is 
 * lower than intervalMinsIncrement
 */
export function getTimeGap(startTime: string, endTime: string, intervalMinsIncrement = 1): number {
    const timeGap = timeDiff(startTime, endTime);
    return (timeGap >= intervalMinsIncrement) ? timeGap : 30;
}

/**
 * return true if time is on current time or in the future.
 */
export function isInTheFuture(time: string): boolean {
    return dayjs().isSameOrBefore(time, 'minutes');
}

/**
 * Return human readable time diff
 * i.e: 15 minutes
 */
export function getReadableTimeDiff(startTime: string, endTime: string): string {
    let response = '';
    let minsDiff = dayjs(endTime).diff(dayjs(startTime), 'minutes');

    if (!minsDiff) {
        return '0 minutes';
    }

    const hoursDiff = Math.floor(minsDiff / 60);
    minsDiff = minsDiff % 60;

    if (hoursDiff === 1) {
        response = '1 hour';
    }
    else if (hoursDiff > 1) {
        response = `${hoursDiff} hours`;
    }

    if (minsDiff === 1) {
        response = `${response} 1 minute`;
    }
    else if (minsDiff > 1) {
        response = `${response} ${minsDiff} minutes`;
    }

    return response;
}

const isStartTimeAvailable = (startTime: any, eventList: Array<any>, ignoreEventId?: string) => {
    if (!eventList || eventList.length === 0) {
        return true;
    }
    const startMoment = dayjs(startTime);
    let isAvailable = true;
  
    for (const event of eventList) {
        // in edit mode, is the same event?
        if (ignoreEventId && ignoreEventId === event.id) {
            continue;
        }
        const { eventStartMoment, eventEndMoment } = _getStoredEventTime(event);
  
        if (startMoment.isBetween(eventStartMoment, eventEndMoment, null, '[]')) {
            isAvailable = false;
        }
    }
  
  
    return isAvailable;
};

/**
 * validates if time selection is available
 * @param {*} event 
 * @param {*} eventList 
 * @param {*} ignoreEventId 
 */
export const isEventTimeAvailable = (event: any, eventList: Array<any>, ignoreEventId?: string) => {
    const startMoment = dayjs(get(event, 'start'));
    const endMoment = dayjs(get(event, 'end')).subtract(1, 'second');
    const startTimeAvailable = isStartTimeAvailable(startMoment, eventList, ignoreEventId);
    const endTimeAvailable = isEndTimeAvailable(endMoment, eventList, ignoreEventId);
    const timeIntervalAvailable = isTimeIntervalAvailable(startMoment, endMoment, eventList, ignoreEventId);
    const isStartTimeIntheFuture = isInTheFuture(startMoment.add(14, 'm').toISOString()); // allow booking in the 15 minutes gap.

    return startTimeAvailable && endTimeAvailable && timeIntervalAvailable && isStartTimeIntheFuture;
};

/**
 * validates if end time is available.
 * @param {*} endTime 
 * @param {*} eventList 
 * @param {*} ignoreEventId 
 * @returns 
 */
const isEndTimeAvailable = (endTime: any, eventList: Array<any>, ignoreEventId?: string) => {
    if (!eventList || eventList.length === 0) {
        return true;
    }

    const endMoment = dayjs(endTime).subtract(1, 'm');
    let isAvailable = true;
  
    for (const event of eventList) {
        // in edit mode, is the same event?
        if (ignoreEventId && ignoreEventId === event.id) {
            continue;
        }
        const { eventStartMoment, eventEndMoment } = _getStoredEventTime(event);
  
        if (endMoment.isBetween(eventStartMoment, eventEndMoment, null, '[]')) {
            isAvailable = false;
        }
    }
  
    return isAvailable;
};

const _getStoredEventTime = (event: any) => {
    const formattedStart = dayjs(event.start.toDate()).format('YYYY-MM-DD HH:mm');
    const formattedEnd = dayjs(event.end.toDate()).format('YYYY-MM-DD HH:mm');
    const eventStartMoment = dayjs(formattedStart);
    const eventEndMoment = dayjs(formattedEnd);
  
    return { eventStartMoment, eventEndMoment };
};

/**
 * It will check if there is other meeting between start and ent time.
 * @param {*} startTime 
 * @param {*} endTime 
 * @param {*} eventList 
 * @param {*} ignoreEventId 
 * @returns 
 */
const isTimeIntervalAvailable = (startTime: any, endTime: any, eventList: Array<any>, ignoreEventId?: string) => {
    if (!eventList || eventList.length === 0) {
        return true;
    }
    const startMoment = dayjs(startTime);
    const endMoment = dayjs(endTime);
    let isAvailable = true;
  
    for (const event of eventList) {
        // in edit mode, is the same event?
        if (ignoreEventId && ignoreEventId === event.id) {
            continue;
        }
        const { eventStartMoment, eventEndMoment } = _getStoredEventTime(event);
  
        if (eventStartMoment.isBetween(startMoment, endMoment, null, '[]') || eventEndMoment.isBetween(startMoment, endMoment, null, '[]')) {
            isAvailable = false;
        }
    }
  
    return isAvailable;
};

/**
 * Return an object with readable start and end time
 * 
 * @param eventData 
 * @returns 
 */
export const displayEventTime = (eventData: any) => {
    const date = dayjs(eventData.start.toDate()).format('D MMM');
    const start = dayjs(eventData.start.toDate()).format('hh:mm A');
    const end = dayjs(eventData.end.toDate()).format('hh:mm A');

    return { 
        start, 
        end,
        string: `${start} - ${end}`,
        fullDateString: `${date}, ${start} - ${end}`,
        date,
    };
};

export const getEventTime = (eventData: any) => {
    const start = eventData.start.toDate().toLocaleString();
    const end = eventData.end.toDate().toLocaleString();
    return { start, end, };
};

export const isInThePast = (time: Date | string) => {
    return dayjs(time).isBefore(dayjs());
};


export const formatEventTime = (time: Date | string): string => {
    return dayjs(time).toISOString();
};
