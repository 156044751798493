/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-check
import { defineStore } from 'pinia';
import router from '@/router';
import { useStorage } from '@vueuse/core';

export const useMainStore = defineStore('main', {
    state: () => ({
        appReady: false,
        checkInSettings: {} as Partial<CheckinSettings>,
        customer: useStorage<Partial<Customer & CustomerExtSettings>>('customer', {}),
        customerId: useStorage('customerId', ''),
        customerTheme: useStorage<Partial<Theme>>('customerTheme', {}),
        employeeId: useStorage('employeeId', ''),
        employeeModel: {} as Employee,
        employees: [] as Employee[],
        desks: [] as Desk[],
        guestModel: useStorage<Partial<LoggedUser>>('visitorData', {}),
        tempDeskId: '', // hold the scaned QR desk ID
        tempLocationId: '', // hold the scaned QR locationId
        userType: useStorage('userType', 'employee'),
        appError: '',
        appStyles: {},
        features: [],
    }),

    getters: {
        loggedUser(): any {
            if (this.employeeModel?.id || this.guestModel?.firstName) {
                return this.userType === 'employee'
                    ? { type: 'employee', ...this.employeeModel }
                    : { type: 'guest', ...this.guestModel };
            } else {
                return null;
            }
        },
        showSideMenu(): boolean {
            return this.loggedUser?.id;
        },
        checkInSettings(): Partial<CheckinSettings> {
            return this.customer?.settings?.check_in;
        },
        hasdeskBookingBetaV2(): boolean {
            return this.features.includes('deskBookingBetaV2');
        },
    },

    actions: {
        setEmployee(employeeData: Employee) {
            this.$patch({ 
                employeeModel: { ...employeeData },
                employeeId: employeeData.id,
            });
        },
        logOut() {
            // this.$patch({
            //     employeeModel: {},
            //     employeeId: '',
            //     guestModel: {},
            //     userType: 'employee',
            // });
            this.employeeModel = {} as Employee;
            this.guestModel = {};
            this.employeeId = '';
            this.userType = 'employee';

            router.push({
                name: 'Home',
                query: {
                    id: router.currentRoute.value.query.id
                }
            });
        },
        setGuestHost(employeeData: Employee) {
            this.guestModel = {
                ...this.guestModel,
                employee: { ...employeeData },
            };
        },
        clearGuestHost() {
            // eslint-disable-next-line no-unused-vars
            const { employee, ...guestData } = this.guestModel;
            this.guestModel = guestData;
        },
    },
});
