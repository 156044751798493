import { useRouter } from 'vue-router';

type UserNavigation = {
    goHome: () => void;
    goto: (routerName: string) => void;
    gotoConfirmation: (type?: string, isVisitor?: boolean | number) => void;
    gotoDeskBooking: (spaceId?: string) => void;
    gotoDeskBookingCompleted: (action?: string, spaceId?: string) => void;
    gotoDeskBookingSearch: () => void;
    gotoDeskDetails: (deskSpaceId: string) => void;
    gotoEditReservation: (deskSpaceId: string, eventId: string) => void;
    gotoEmployeeHome: () => void;
    gotoQrScanner: () => void;
    gotoReservations: () => void;
    gotoVisitorHostSelect: () => void;
}

export default function useNavigation(): UserNavigation {
    const router = useRouter();

    function goto(routerName: string): void {
        router.push({
            name: routerName
        });
    }

    function gotoQrScanner(): void {
        router.push({
            name: 'QrScanner',
        });
    }

    function gotoDeskDetails(deskSpaceId: string): void {
        router.push({
            name: 'DeskBookingDetails',
            params: {
                deskSpaceId
            },
        });
    }

    function gotoEditReservation(deskSpaceId: string, eventId: string): void {
        router.push({
            name: 'EditReservation',
            params: {
                deskSpaceId,
                eventId,
            },
        });
    }

    function gotoDeskBooking(spaceId?: string): void {
        if (spaceId) {
            return gotoDeskDetails(spaceId);
        }
        router.push({
            name: 'DeskBookingSearch',
        });
    }

    function gotoEmployeeHome(): void {
        router.push({
            name: 'EmployeeHome',
        });
    }

    function gotoDeskBookingSearch(): void {
        router.push({
            name: 'DeskBookingSearch',
        });
    }

    function gotoDeskBookingCompleted(action?: string, spaceId?: string): void {
        router.push({
            name: 'DeskBookedConfirmation',
            query: {
                action: action || 'create',
                spaceId,
            }
        });
    }

    function gotoConfirmation(type = '', isVisitor: number | boolean = false): void {
        const route = {
            name: 'Confirmation',
            query: {
                type,
            } as any
        };
        if (isVisitor) {
            route.query.isVisitor = 1;
        }
        router.push(route);
    }

    function gotoVisitorHostSelect(): void {
        router.push({
            name: 'VisitorHostSelect',
        });
    }

    function gotoReservations(): void {
        router.push({
            name: 'MyReservations',
        });
    }

    function goHome(): void {
        router.replace({
            name: 'Home',
        });
    }

    return {
        goHome,
        goto,
        gotoConfirmation,
        gotoDeskBooking,
        gotoDeskBookingCompleted,
        gotoDeskBookingSearch,
        gotoDeskDetails,
        gotoEditReservation,
        gotoEmployeeHome,
        gotoQrScanner,
        gotoReservations,
        gotoVisitorHostSelect,
    };
}