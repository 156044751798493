import { ref, watch } from 'vue';
import { get } from 'lodash';
import { alert } from '../utils/frameworkFeatures';

interface BeforeInstallPromptEvent extends Event {
    readonly platforms: string[];
    readonly userChoice: Promise<UserChoice>;
    prompt(): Promise<void>;
}

type UserChoice = {
    outcome: 'accepted' | 'dismissed';
    platform: string;
}

function isOnStandalone(): boolean {
    return get(navigator, 'standalone') || window.matchMedia('(display-mode: standalone)').matches;
}

export default function (): void {
    const deferredPrompt = ref(null);
    const installRequested = ref(false);
    const userResponse = ref('');
    const isUsingApp = ref(isOnStandalone());

    function onBeforeInstallPrompt(event: Event): BeforeInstallPromptEvent {
        event.preventDefault();
        // Stash the event so it can be triggered later.
        deferredPrompt.value = event;
        return;
    }

    if (!isUsingApp.value) {
        window.addEventListener('beforeinstallprompt', onBeforeInstallPrompt);
        // window.addEventListener('onInstall', () => {});
    }

    /** displays a custom alert to promp for app install */
    async function requestInstall(): Promise<void> {
        await alert({
            message: 'Whould you like to add Turf app in your home screen?',
            buttons: [
                {
                    text: 'Not now',
                    role: 'cancel',
                },
                {
                    text: 'Yes',
                    handler: promptInstall,
                },
            ],
        });
        installRequested.value = true;
    }

    /** execute deferred prompt to installthe app */
    function promptInstall(): void {
        if (deferredPrompt.value) {
            deferredPrompt.value.prompt();

            deferredPrompt.value.userChoice.then(function (choiceResult: UserChoice) {
                console.log('[App] App install user response: ' + choiceResult.outcome);
                userResponse.value = choiceResult.outcome;
            });
        }
    }

    const deferWatcherStop = watch(deferredPrompt, (deferredPrompt: any) => {
        setTimeout(() => {
            if (deferredPrompt && !installRequested.value) {
                requestInstall();
                deferWatcherStop();
            }
        }, 2000);
    });
}