import get from 'lodash/get';
import turfCore from '@skykit-dev/skykit-turf-core';
import { defaultTheme } from '@/config/app.config';
import ColorShader from '@/utils/colorShader';

export function setCustomerSettings (customerData: Customer): Customer & CustomerExtSettings {
    const hasDeskBooking = !!get(customerData, 'settings.booking.desk.is_enable');
    const hasSpaceBooking = !!get(customerData, 'settings.booking.space.is_enable');
    const hasCheckin = !!get(customerData, 'settings.check_in.is_enable');
    const hasEmployeeCheckin = !!get(customerData, 'settings.check_in.requiredUserTypes.employees');
    const hasVisitorCheckin = !!get(customerData, 'settings.check_in.requiredUserTypes.guests');
    const hasOnlyCheckIn = hasCheckin && !hasSpaceBooking && !hasDeskBooking;
    const hasOnlyEmployeeCheckIn = hasEmployeeCheckin && !hasVisitorCheckin && hasOnlyCheckIn;
    const hasOnlyVisitorCheckIn = !hasEmployeeCheckin && hasVisitorCheckin && hasOnlyCheckIn;
    const hasOnlyDeskBooking = !hasCheckin && hasDeskBooking && !hasSpaceBooking;
    const hasOnlySpaceBooking = !hasCheckin && !hasDeskBooking && hasSpaceBooking;

    return {
        ...customerData,
        hasDeskBooking,
        hasSpaceBooking,
        hasBooking: hasDeskBooking,
        hasCheckin: hasCheckin && (hasEmployeeCheckin || hasVisitorCheckin),
        hasVisitorCheckin: hasCheckin && hasVisitorCheckin,
        hasEmployeeCheckin: hasCheckin && hasEmployeeCheckin,
        hasOnlyCheckIn,
        hasOnlyEmployeeCheckIn,
        hasOnlyVisitorCheckIn,
        hasOnlyDeskBooking,
        hasOnlySpaceBooking,
    };
}

export const getAppStyles = (themeSettings: any) =>  {
    const bgColorOrImg = (themeSettings && themeSettings.background_image) ? 'none' : themeSettings && themeSettings.background_color || defaultTheme.backgroundColor;
    return {
        '--ion-color-primary': themeSettings && themeSettings.primary_color || defaultTheme.primaryColor,
        '--ion-color-secondary': themeSettings && themeSettings.primary_color ? ColorShader(themeSettings && themeSettings.primary_color) : defaultTheme.secondaryColor,
        '--ion-background-color': bgColorOrImg,
        '--background': bgColorOrImg,
        '--background-rgb': '0, 0, 0', // this is realy awkward
        '--ion-text-color': themeSettings && themeSettings.font_color || defaultTheme.fontColor,
        '--app-logo': themeSettings && themeSettings.logo || '',
        '--app-background-image': themeSettings && themeSettings.background_image || '',
        '--app-confirmation-img': themeSettings && themeSettings.confirmation_image || '',
        '--ion-toolbar-background': bgColorOrImg,
        '--ion-tab-bar-background': themeSettings && themeSettings.background_color || defaultTheme.backgroundColor,
    };
};

export const getCustomerData = async (customerId: string) => {
    const customerTheme = await turfCore.customer.getTheme(customerId);

    const customer = await turfCore.customer.get(customerId);
    delete customer.token;
    delete customer.client_id;

    return {
        customer: setCustomerSettings(customer),
        customerTheme,
    };
};
