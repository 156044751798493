
import { IonApp } from '@ionic/vue';
import { defineComponent, onMounted, watch } from 'vue';
import useAppInstall from '@/composables/useAppInstall';
import { toast } from '@/utils/frameworkFeatures';
import SideMenu from '@/components/SideMenu.vue';
import { useMainStore } from '@/stores/main.store';
import router from '@/router';
import { parseQrUrl } from '@/utils/utils';
import { initApp } from '@/utils/app.utils';
import { initialised } from './plugins/firestore';

export default defineComponent({
    name: 'App',
    components: {
        IonApp,
        SideMenu
    },
    setup() {
        const store = useMainStore();

        // get the query id
        const uri = window.location.search.substring(1); 
        const params = new URLSearchParams(uri);
        const queryId = decodeURIComponent(params.get('id'));

        // watch on firebase initialize and authenticate
        const initUnwatcher = watch(initialised, (initialised) => {
            if (initialised) {
                initUnwatcher();
                processQueryId();
            }
        });

        function processQueryId() {
            if (queryId) {  // TODO ;check for id in storage
                try {
                    const { customerId, spaceId = '', locationId = '', error } = parseQrUrl(window.location.href);
                    if (!customerId || error) {
                        router.push({ name: 'QrScanner' });
                    } else {
                        initApp({ customerId, spaceId, locationId })
                            .catch((error) => {
                                console.error('QR Error', error.message);
                                router.push({ name: 'QrScanner' });
                            });
                    }
                    
                } catch(err) {
                    router.push({ name: 'QrScanner' });
                }
            } else if (store.customerId) {
                initApp({ 
                    customerId: store.customerId, 
                    spaceId: store.tempDeskId || '', 
                    locationId: store.tempLocationId || '',
                });
            } else {
                router.push({ name: 'QrScanner' });
            }
        }

        useAppInstall();

        // TODO:
        const swToastInit = async (e: any) => {
            const swRegistration = e?.originalEvent?.registration || e?.detail;

            toast({
                message: 'New content is available. Please refresh the page.',
                position: 'bottom',
                duration: 3600000,
                buttons: [
                    {
                        text: 'Refresh',
                        role: 'cancel',
                        handler: () => {
                            swRegistration.waiting.postMessage({ type: 'SKIP_WAITING' });

                            setTimeout(() => window.location.reload(), 2000);
                        },
                    },
                ],
            });
        };

        onMounted(() => {
            document.addEventListener('serviceWorkerUpdateEvent', swToastInit);
        });

        return { 
            store,
            initialised,
        };
    },
});
