/***
 * from a list of desks, get unique buildings
 */
export function getUniqueBuldings(deskSpaces: any[]): Building[] {
    // removed id since multiple buildings can have the same name and cause duplicates
    const buildingsSet = new Set([...deskSpaces.map(d => JSON.stringify({ name: d.turf_meta.building_name }))]);
    return ([...buildingsSet]).map(b => JSON.parse(b));
}

/***
 * from a list of desks, get unique buildings
 */
export function getUniqueFloor(deskSpaces: any[]): Floor[] {
    const floorsSet = new Set([...deskSpaces.map(d => JSON.stringify({ name: d.turf_meta.floor, id: d.turf_meta.floor }))]);
    return ([...floorsSet])
        .map(b => JSON.parse(b))
        .sort((a, b) => a.name > b.name ? 1 : -1);
}

/***
 * from a list of desks, get unique zones
 */
export function getUniqueZones(deskSpaces: Desk[]): any[] {
    const validZones: Desk[] = deskSpaces.filter((d: Desk) => d.turf_meta.zone);
    const zoneSet = new Set([...validZones.map(d => JSON.stringify({ name: d.turf_meta.zone, id: d.turf_meta.zone }))]);
    return ([...zoneSet]).map(b => JSON.parse(b)).sort((a: any, b: any) => a.name > b.name ? 1 : -1);
}
