import turfCore from '@skykit-dev/skykit-turf-core';
import { getCustomerData, getAppStyles } from '@/utils/customer.utils';
import { useMainStore } from '@/stores/main.store';
import { watchCollection } from '@/services/firestore';

let ffCollectionWatcher: () => any;

export const initApp = async ({ customerId, spaceId = '', locationId = '' }: any) => {
    const store = useMainStore();
    
    if (!customerId) {
        console.error('Missing customerId in initApp');
        throw new Error('QR is not valid');
    }
    
    turfCore.setCustomerId(customerId);
  
    return getCustomerData(customerId)
        .then(({ customerTheme, customer }) => {
            let employeeSynced = false, deskSynced = false;
            
            // user has scaned a desk QR but customer does not have desk booking enabled
            if (!customer.hasDeskBooking && spaceId) {
                throw new Error('Desk booking is not enabled');
            }

            ffCollectionWatcher && ffCollectionWatcher();
            ffCollectionWatcher = watchCollection<FeatureFlags>('feature_flags', (snapshot) => {
                const features = snapshot.docs
                    .map(d => d.data())
                    .filter(f => f.customers.includes(customerId))
                    .map(f => f.id);

                store.features = features;
            });

            store.$patch({
                customer,
                customerTheme,
                customerId: customerId,
                tempDeskId: spaceId,
                tempLocationId: locationId,
                appStyles: getAppStyles(customerTheme),
            });

            turfCore.customer.employee.subscribe((employees: Employee[]) => {
                employeeSynced = true;
                store.$patch({ employees, });

                if (deskSynced) {
                    store.$patch({ appReady: true, });
                }
            });

            turfCore.customer.space.subscribeToSpaces((rooms: Desk[]) => {
                const desks = rooms.filter(d => d.space_type === 'desk' && d.active && d.turf_meta.is_assigned);
                deskSynced = true;
                store.$patch({ desks, });
                
                if (employeeSynced) {
                    store.$patch({ appReady: true, });
                }
            });

            if (store.employeeId) {
                turfCore.customer?.employee?.get(store.employeeId)
                    .then((employee: Employee) => {
                        employee.id = store.employeeId;
                        store.$patch({
                            employeeModel: employee,
                            userType: 'employee',
                        });
                    });
            }
        });
};