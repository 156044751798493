/* eslint-disable no-fallthrough */
import { v4 as uuidv4 } from 'uuid';
import firestoreService from '@/services/firestore';
import { watchCollection } from '@/services/firestore';
import { formatEventTime } from '@/utils/date.utils';

class BookingService {
    eventQueue: any[];

    constructor() {
        this.eventQueue = [];
    }

    /**
     * book a desk. No return, it only throw error if can not book
     * @param deskSpaceId 
     * @param startTime 
     * @param endTime 
     */
    async bookDesk(
        {
            deskSpaceId,
            timeZone,
            startTime,
            endTime,
            customerId,
            employee,
        }: {
            deskSpaceId: string;
            timeZone: string;
            startTime: string;
            endTime: string;
            customerId: string;
            employee: Partial<Employee>;
        },
        callback: (error?: string) => void,

    ): Promise<void | never> {
        const eventTitle = employee && employee.fullName ? `Booked by ${employee.fullName}` : 'Booked';
        const docId = uuidv4().replace(/-/g, '');
        const fbDoc = firestoreService.db().collection('desk_booking_event_queue').doc(docId);

        const data = {
            eventTitle,
            start: {
                dateTime: formatEventTime(startTime),
                timeZone
            },
            end: {
                dateTime: formatEventTime(endTime),
                timeZone
            },
            customerId,
            deskSpaceId,
            employeeId: employee.id || '',
        };

        try {
            fbDoc.set(data);
        } catch (error: any) {
            callback(error.message);
        }

        const timeout = setTimeout(() => callback('Timeout'), 20000);

        const unsub = fbDoc.onSnapshot((doc) => {
            if (doc.exists) {
                const docData = doc.data();
                if (docData.error) {
                    callback(docData.error);
                    unsub();
                    clearTimeout(timeout);
                }
            } else {
                callback();
                unsub();
                clearTimeout(timeout);
            }
        });

        // just for safety, make sure the subscription is done
        setTimeout(() => {
            typeof unsub === 'function' && unsub();
        }, 600000);
    }

    async editReservation(
        payload: { customer: Partial<Customer>; [key: string]: any }, 
        callback: (error?: string) => void
    ) {
        const event = payload.event;
        const customer = payload.customer;
        const docId = `${customer.id}/${event.room_id}/${event.id}`;
        const fbDoc = firestoreService.db().collection('update_event_queue').doc(docId);

        try {
            const data = {
                roomEmail: event.organizer.email,
                userPrincipalName: event.organizer.email,
                customerTypeCode: customer.type,
                timeZone: payload.timeZone,
                start: {
                    dateTime: formatEventTime(payload.start),
                    timeZone: payload.timeZone,
                },
                end: {
                    dateTime: formatEventTime(payload.end),
                    timeZone: payload.timeZone,
                },
                eventTitle:  event.sommary || event.subject,
            };

            fbDoc.set(data);
        } catch (error: any) {
            console.error(error);
            callback(error.message);
        }

        const timeout = setTimeout(() => callback('Timeout'), 20000);

        const unsub = fbDoc.onSnapshot((doc) => {
            if (doc.exists) {
                const docData = doc.data();
                if (docData.error) {
                    console.error(docData.error);
                    callback(docData.error);
                    unsub();
                    clearTimeout(timeout);
                }
            } else {
                callback();
                unsub();
                clearTimeout(timeout);
            }
        });

        // just for safety, make sure the subscription is done
        setTimeout(() => {
            typeof unsub === 'function' && unsub();
        }, 600000);
    }

    /**
     * Cancel a desk reservation
     * @param event 
     * @param callback 
     */
    async cancelReservation(
        payload: { customer: Partial<Customer>; event: any }, 
        callback: (error?: string) => void
    ): Promise<void | never> {
        const event = payload.event;
        const customer = payload.customer;
        const docId = `${customer.id}/${event.room_id}/${event.id}`;
        const fbDoc = firestoreService.db().collection('cancel_event_queue').doc(docId);

        try {
            const data: any = {
                roomEmail: event.organizer.email,
                userPrincipalName: event.organizer.email,
                customerTypeCode: customer.type,
                meetingManagerEmail: customer.meeting_manager_email || '',
            };

            fbDoc.set(data);
        } catch (error: any) {
            callback(error.message);
        }

        const timeout = setTimeout(() => callback('Timeout'), 20000);

        const unsub = fbDoc.onSnapshot((doc) => {
            if (doc.exists) {
                const docData = doc.data();
                if (docData.error) {
                    callback(docData.error);
                    unsub();
                    clearTimeout(timeout);
                }
            } else {
                callback();
                unsub();
                clearTimeout(timeout);
            }
        });

        // just for safety, make sure the subscription is done
        setTimeout(() => {
            typeof unsub === 'function' && unsub();
        }, 600000);
    }

    // desks binding *********************************************************
    unBindDesks: () => void;

    /**
     * unbind previous binding
     */
    unBindDesksList() {
        this.unBindDesks && this.unBindDesks();
    }

    /**
     * Bind firebase rooms (desks) collection to a ref value
     * @param customerId 
     * @returns 
     */
    bindDesks(customerId: string, onSnalshotCb: any): void {
        this.unBindDesksList(); // unbind if is a previous binding

        const collPath = `customers/${customerId}/rooms`;
        const queryOptions: FirestoreQuery = {
            // this can be a bug. should we filter assigned?
            where: ['space_type', '==', 'desk']
        };
        this.unBindDesks = watchCollection<Desk>(collPath, onSnalshotCb, queryOptions);
    }

    /**
     * unbind previous event binding
     */
    unBindEvents() {
        if (this.eventQueue.length) {
            this.eventQueue.forEach((fn: any) => fn());
            this.eventQueue = [];
        }
    }

    /**
     * Bind firebase space events
     * @param spaceId 
     * @returns 
     */
    bindEvents(customerId: string, spaceId: string, onSnalshotCb: any): void {
        const collPath = `customers/${customerId}/rooms/${spaceId}/events`;
        this.eventQueue.push(watchCollection<Desk>(collPath, onSnalshotCb));
    }
}

export default new BookingService;