import { toastController, loadingController, alertController } from '@ionic/vue';

// TODO: find Ionic ToastOptions type
type ToasterOptions = {
    header?: string;
    message: string;
    position?: 'bottom' | 'top' | 'middle';
    cssClass?: string;
    color?: string;
    duration?: number | null;
    buttons?: any[];
}

export async function toast(options: ToasterOptions): Promise<HTMLIonToastElement> {
    const toast = await toastController
        .create({
            header: options.header || '',
            message: options.message || '',
            cssClass: options.cssClass || '',
            duration: options.duration || 5000,
            position: options.position || 'bottom',
            buttons: options.buttons || null,
            color: options.color || 'danger',
        });
    toast.present();
    return toast;
}

export async function loading(options?: { message: string }): Promise<HTMLIonLoadingElement> {
    const loading = await loadingController.create({
        duration: 30000, // just in case
        ...options,
    });
    await loading.present();
    return loading;
}

export async function alert(options: any): Promise<void> {
    const alert = await alertController.create(options);
    alert.present();
}
