/**
* return search param 'id' if any
* @param {string} url
*/
export function parseQrUrl(url: string): {
    error?: string;
    customerId?: string;
    contactEmail?: string;
    locationId?: string;
    spaceId?: string;
} {
    let data;
    try {
        const parsedUrl = new URL(url);
        const id = parsedUrl.searchParams.get('id');
        data = JSON.parse(atob(id)) as QueryStringData;
    } catch(error) {
        return {
            error: 'Unable to read QR code'
        };
    }

    if (!data || !data.customerId) {
        return { error: 'Invalid QR code' };
    } else {
        return data;
    }
}
