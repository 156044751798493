const defaultTheme = {
    primaryColor: '#6e2ed2',
    secondaryColor: '#8c49ef',
    backgroundColor: '#000000',
    fontColor: '#ffffff',
};

const AppErrorState = {
    'INVALID_ID': 'invalid_id',
    'NO_ID': 'no_id'
};


const employeeAppSelections = [
    {
        name: 'CHECK IN',
        value: 'check-in'
    },
    {
        name: 'BOOK A DESK',
        value: 'desk-booking'
    }
];

export {
    defaultTheme,
    AppErrorState,
    employeeAppSelections
};