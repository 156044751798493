const collectionNames = {
    CUSTOMERS: 'customers',
    EVENTS: 'events',
    ROOMS: 'rooms',
    SUBSCRIPTIONS: 'subscriptions',
    TENANT_CODES: 'tenant_codes',
    DISPLAYS: 'displays',
    THEMES: 'themes',
    CHECKIN_GUESTS: 'checkin_guests',
    CHECKIN_EMPLOYEES: 'checkin_employees',
    CHECKIN_LOGS: 'checkin_logs',
    CHECKIN_GUIDS: 'checkin_guids',
    CHECKIN_SPACES: 'checkin_spaces'
};

export {
    collectionNames
};