
import { useMainStore } from '@/stores/main.store';
import { storeToRefs } from 'pinia';

export default function () {
    const store = useMainStore();
    const { 
        customerId, 
        tempLocationId: locationId,
        employees,
        tempDeskId: spaceId,
        customer,
        checkInSettings,
    } = storeToRefs(store);

    const uri = window.location.search.substring(1); 
    const params = new URLSearchParams(uri);
    const queryId = params.get('id');

    return {
        customerId,
        locationId,
        employees,
        spaceId,
        customer,
        checkInSettings,
        queryId,
    };
}
