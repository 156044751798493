import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideMenu = _resolveComponent("SideMenu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, {
    style: _normalizeStyle(_ctx.store.appStyles)
  }, {
    default: _withCtx(() => [
      (_ctx.store.showSideMenu)
        ? (_openBlock(), _createBlock(_component_SideMenu, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_router_view, { id: "main-content" }, {
        default: _withCtx(({ Component }) => [
          (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["style"]))
}