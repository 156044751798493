<template>
    <ion-menu side="end" content-id="main-content">
        <ion-header>
            <div class="side-menu-header">
                <ion-icon :icon="personCircle" style="font-size: 80px; color: rgba(255, 255, 255, .7)" />
                <h4>{{loggedUser.firstName}}</h4>
            </div>
        </ion-header>
        <ion-content>
            <ion-list>
                <ion-item 
                    @click="gotoReservations" 
                    v-if="userType === 'employee' && store.customer.hasDeskBooking && store.hasdeskBookingBetaV2 /* TODO: deskBookingBetaV2 feature flag */"
                >
                    <ion-badge color="warning" slot="end" v-if="employeeReservations.length">{{ employeeReservations.length }}</ion-badge>
                    <ion-label>My reservations</ion-label>
                </ion-item>
                <ion-item @click="logout" v-if="loggedUser">
                    <ion-icon :icon="logOutOutline" slot="end"></ion-icon>
                    <ion-label>Log out</ion-label>
                </ion-item>
            </ion-list>
        </ion-content>
    </ion-menu>
</template>

<script>
import { menuController } from '@ionic/vue';
import useNavigation from '../composables/useNavigation';
import useDeskBooking from '@/composables/useDeskBooking';
import { alert } from '@/utils/frameworkFeatures';
import { useMainStore } from '@/stores/main.store';
import { storeToRefs } from 'pinia';
import { logOutOutline, close, personCircle } from 'ionicons/icons';

export default {
    name: 'SideMenu',
    setup() {
        const { gotoReservations } = useNavigation();
        const { employeeReservations } = useDeskBooking();
        const store = useMainStore();
        

        const { loggedUser, userType } = storeToRefs(store);

        function closeMenu() {
            menuController.close();
        }

        async function logout() {
            closeMenu();
            await alert({
                header: 'Are you sure you want to log out?',
                message: 'Logging out will clear all user data in this app.',
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                    },
                    {
                        text: 'Yes',
                        handler: () => {
                            store.logOut();
                        },
                    },
                ],
            });
        }

        return {
            close,
            closeMenu,
            gotoReservations,
            logOutOutline, 
            loggedUser,
            logout,
            personCircle,
            userType,
            store,
            employeeReservations,
        };
    },
};
</script>

<style lang="scss">
.side-menu-header {
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0 30px 0;
}
</style>