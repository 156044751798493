import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { TrackJS } from 'trackjs';
import { version } from '../package.json';
import App from './App.vue';
import router from './router';

import { IonicVue } from '@ionic/vue';

// dayjs
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import relativeTime from 'dayjs/plugin/relativeTime';
import isBetween from 'dayjs/plugin/isBetween';
import timezone from 'dayjs/plugin/timezone';

import BaseLayout from './components/BaseLayout.vue';
import './registerServiceWorker';

// plugins
import Firestore from './plugins/firestore';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* ionic components */
import {
    IonBadge,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonModal,
    IonPage,
    IonRow,
    IonSearchbar,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    IonText
} from '@ionic/vue';

if (location.host.indexOf('localhost') !== 0) {
    TrackJS.install({
        token: process.env.VUE_APP_TRACKJS_TOKEN,
        application: process.env.VUE_APP_TRACKJS_NAME
    });

    TrackJS.addMetadata('version', version);
}

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(relativeTime);
dayjs.extend(isBetween);
dayjs.extend(timezone);

const app = createApp(App)
    .use(Firestore)
    .use(createPinia())
    .use(IonicVue, {
        rippleEffect: true,
        mode: 'md'  // force the mode to be Material Design to be consistent across ios/android
    })
    .use(router);

app.component('base-layout', BaseLayout);

app.component('IonButton', IonButton);
app.component('IonButtons', IonButtons);
app.component('IonCol', IonCol);
app.component('IonContent', IonContent);
app.component('IonDatetime', IonDatetime);
app.component('IonFooter', IonFooter);
app.component('IonGrid', IonGrid);
app.component('IonHeader', IonHeader);
app.component('IonIcon', IonIcon);
app.component('IonItem', IonItem);
app.component('IonLabel', IonLabel);
app.component('IonList', IonList);
app.component('IonModal', IonModal);
app.component('IonPage', IonPage);
app.component('IonRow', IonRow);
app.component('IonSearchbar', IonSearchbar);
app.component('IonSelect', IonSelect);
app.component('IonSelectOption', IonSelectOption);
app.component('IonTitle', IonTitle);
app.component('IonToolbar', IonToolbar);
app.component('IonText', IonText);
app.component('IonMenu', IonMenu);
app.component('IonBadge', IonBadge);

router.isReady().then(() => {
    app.mount('#app');
});

export {
    app
};