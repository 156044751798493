import dayjs from 'dayjs';
import { ref, watch, computed } from 'vue';
import { intervalMinsIncrement } from '@/config';
import router from '@/router/index';
import orderBy from 'lodash/orderBy';

import bookingService from '@/services/booking.service';

import { toast, loading, alert } from '@/utils/frameworkFeatures';
import { getNextTimeInterval, getTimeGap, timeDiff, isEventTimeAvailable, getEventTime, isInThePast } from '@/utils/date.utils';
import { getUniqueBuldings, getUniqueFloor, getUniqueZones } from '@/utils/deskBooking.utils';
import { getReadableTimeDiff } from '../utils/date.utils';

import useNavigation from './useNavigation';
import useAppState from './useAppState';
import { useMainStore } from '@/stores/main.store';
import { storeToRefs } from 'pinia';
import turfCore from '@skykit-dev/skykit-turf-core';

const deskSpaceEvents = ref<any>(new Map());
const boundSpace: string[] = [];

// default valur for pristine startTime input on filters form
const initialStartTime = ref<string>();
// default valur for pristine endTime input on filters form
const initialEndTime = ref<string>();
// reset sorting
const resetSort = ref(false);

function resetInitialTime() {
    initialStartTime.value = getNextTimeInterval(intervalMinsIncrement);
    initialEndTime.value = dayjs(initialStartTime.value).add(30, 'm').toISOString();
}

resetInitialTime();

// contain the selected desk model data whe user select a desk from the search results
const selectedDeskSpace = ref<Desk>();

// hold the event info when edition a desk reservation
const editingEvent = ref<Desk>();

// filters model
const deskFiltersModel = ref<DeskFilters>({
    buildingId: '',
    floor: '',
    zone: '',
    startTime: initialStartTime.value,
    endTime: dayjs(initialStartTime.value).add(30, 'm').toISOString(),
});

// contain the time duration string
const timeGapText = computed(() => getReadableTimeDiff(
    deskFiltersModel.value.startTime,
    deskFiltersModel.value.endTime
));

// TODO use turfCore space events subscription
function onSnapshotUpdateDesksEventsList(snapshot: any): void {
    for (const change of snapshot.docChanges()) {
        const eventId = change.doc.id;
        const spaceId = change.doc.ref.parent.parent.id;
        const data = {
            ...change.doc.data(),
            id: eventId,
        };

        // lets be consisten with the organizer object
        if (data.organizer && data.organizer.emailAddress) {
            data.organizer = {
                email: data.organizer.emailAddress.address,
                displayName: data.organizer.emailAddress.name || '',
            };
        }

        const { customer } = useAppState();

        let prop: string;

        if (customer.value.type === 'google') {
            prop = 'google_id';
        } else if (customer.value.type === 'microsoft') {
            prop = 'microsoft_id';
        } else {
            return console.error('Customer not set to determine event');
        }

        const exist = deskSpaceEvents.value.get(spaceId).find((e: any) => {
            return e[prop] === data[prop];
        });

        if (change.type === 'added' && !exist) {
            deskSpaceEvents.value.get(spaceId).push(data);
        } else if (change.type === 'modified') {    // replace existing obj with new obj
            const updatedEvents = deskSpaceEvents.value.get(spaceId).map((e: any) => e[prop] === data[prop] ? data : e);
            deskSpaceEvents.value.set(spaceId, updatedEvents);
        } else if (change.type === 'removed') {
            const updatedEvents = deskSpaceEvents.value.get(spaceId).filter((e: any) => e[prop] !== data[prop]);
            deskSpaceEvents.value.set(spaceId, updatedEvents);
        }
    }
}

/**
 * Update start/end time in the filters model
 */
function onFiltersTimeUpdate(
    { target: { value: time } }: any,
    which: 'startTime' | 'endTime'
): void {
    if (which === 'startTime') {
        // shift endTime to match the previous time gap
        const timeGap = getTimeGap(deskFiltersModel.value.startTime, deskFiltersModel.value.endTime, intervalMinsIncrement);
        initialEndTime.value = dayjs(time).add(timeGap, 'm').toISOString();
        deskFiltersModel.value.endTime = initialEndTime.value;
    }

    deskFiltersModel.value[which] = time;
}

/**
 * apply filters to desk list. {@link deskSpacesFiltered}
 * @param deskSpace 
 * @param filters 
 * @returns boolean
 */
function applyDeskFilters(deskSpace: Desk, filters: any) {
    return (
        (!filters.buildingId || deskSpace.turf_meta.building_name === filters.buildingId) // by building, (if any)
        && (!filters.floor || deskSpace.turf_meta.floor.toString() === filters.floor.toString()) // by floor (if any)
        && (!filters.zone || deskSpace.turf_meta.zone.toString() === filters.zone.toString()) // by zone (if any)
    );
}

/**
 * useDeskBooking
 */
export default function useDeskBooking() {
    const { customerId, customer } = useAppState();
    const store = useMainStore();
    const { employeeModel, desks: deskSpaces } = storeToRefs(store);
    const { gotoDeskBookingCompleted } = useNavigation();


    // buildings from desk lists
    const buildlingsList = computed<Building[]>(() => {
        const buildings = deskSpaces.value
            ? getUniqueBuldings(deskSpaces.value)
            : [];
    
        buildings.unshift({ name: 'All', id: '' });
        return buildings;
    }
    );

    // return only floors in selected building
    const floorsInSelectedBuilding = computed<Floor[]>(() => {
        const floors = getUniqueFloor(deskSpapcesInBuildingFilter.value);
        //@ts-ignore // need to fix Floor type in turf-core
        floors.unshift({ name: 'All', id: '' });
        return floors;
    });

    // zones
    const zonesInSelectedBuilding = computed<any[]>(() => {
        const zones =  deskSpaces.value && buildlingsList.value && getUniqueZones(deskSpapcesInBuildingFilter.value);
        zones.unshift({ name: 'All', id: '' });
        return zones;
    });

    // desk list filtered by deskFiltersModel
    const deskSpacesFiltered = computed<Desk[]>(() => {
        return deskSpaces.value.filter((deskSpace: any) => applyDeskFilters(deskSpace, deskFiltersModel.value));
    });

    /**
     * 
     * @param {string} spaceId
     * @description checks to see if the start/end time exists
     * @returns boolean
     */
    const isAvailable = (spaceId: string) => {
        const newEvent = {
            start: deskFiltersModel.value.startTime,
            end: deskFiltersModel.value.endTime
        };

        return isEventTimeAvailable(newEvent, deskSpaceEvents.value.get(spaceId), editingEvent.value?.id);
    };

    const allRoomsEvents = computed(() => {
        // get all events from all rooms
        return [...deskSpaceEvents.value].map(ex => ex[1].map((e: any) => ({...e}))).flat();
    });
    
    const employeeReservations = computed(() => {
        const r = allRoomsEvents.value
            .filter((event: any) => {
                const { end } = getEventTime(event);
                return !isInThePast(end) && event.employee?.email === employeeModel.value?.email;
            })
            .map((event: any) => ({
                ...event,
                space: deskSpaces.value.find((space) => space.id === event.room_id)
            }));

        return r;
    });

    function syncDeskEvents() {
        const spaceIds = deskSpaces.value.map((x: Desk) => x.id);
        for (const spaceId of spaceIds) {
            if (boundSpace.includes(spaceId)) {
                continue;
            }

            deskSpaceEvents.value.set(spaceId, []);
            boundSpace.push(spaceId);
            bookingService.bindEvents(customerId.value, spaceId, onSnapshotUpdateDesksEventsList);
        }
    }

    // desk list filtered only be selected building
    const deskSpapcesInBuildingFilter = computed<Desk[]>(() => {
        if (!deskFiltersModel.value.buildingId) {
            return deskSpaces.value;
        }
        return deskSpaces.value.filter((deskSpace: any) => deskFiltersModel.value.buildingId === deskSpace.turf_meta.building_name);
    });

    // wait for builidings list and populate the select box with the first option
    watch(buildlingsList, (buildlingsList: Building[]): void => {
        if (!buildlingsList || !buildlingsList[0]) {
            return;
        }
        // TODO: localStore last used buildingId and populate as default
        // deskFiltersModel.value.buildingId = deskFiltersModel.value.buildingId || buildlingsList[0].id;
    });

    // wait for floors list and populate the select box with the first option
    watch(floorsInSelectedBuilding, (floorsList: Floor[]): void => {
        if (!floorsList || !floorsList[0]) {
            return;
        }

        if (deskFiltersModel.value.floor) {
            const floorNamesList = floorsList.map(f => f.name);
            deskFiltersModel.value.floor = (deskFiltersModel.value.floor && floorNamesList.includes(deskFiltersModel.value.floor))
                ? deskFiltersModel.value.floor
                : floorNamesList[0];
        }
    });

    watch(zonesInSelectedBuilding, (zoneList: any[]): void => {
        if (!zoneList || !zoneList[0]) {
            return;
        }

        if (deskFiltersModel.value.zone) {
            deskFiltersModel.value.zone = deskFiltersModel.value.zone ||zoneList[0].id;
        }
    });

    /**
     * Sets the start & end time on the inputs based on time in filters
     */
    function setInitialTime() {
        initialStartTime.value = deskFiltersModel.value.startTime;
        initialEndTime.value = deskFiltersModel.value.endTime;
    }

    // start time must be in the future and end time should be at least 15 past start time
    const isValidTimeSlot = computed<boolean>(() => {
        const dateDiffMins = timeDiff(deskFiltersModel.value.startTime, deskFiltersModel.value.endTime);


        return (
            dateDiffMins >= intervalMinsIncrement // time gap is at leas min time interval (15 mins)
        );
    });

    /**
     * sets selectedDeskSpace based on route.params.deskSpaceId
     * @returns 
     */
    function setDeskSpace() {
        const deskSpaceId = store.tempDeskId || router?.currentRoute?.value?.params?.deskSpaceId;
        store.tempDeskId = '';
        if (!deskSpaces?.value) {
            return;
        }
        
        // if you are editing an event...
        const eventId = router?.currentRoute?.value?.params?.eventId;
        if (eventId) {
            const eventData = allRoomsEvents.value && allRoomsEvents.value.find((e: any) => e.id === eventId);
            
            // check if the event exists
            if (eventData) {
                editingEvent.value = { ...eventData, id: eventId };
                
                const { start, end } = getEventTime(eventData);
                initialStartTime.value = start;
                initialEndTime.value = end;
                deskFiltersModel.value.startTime = start;
                deskFiltersModel.value.endTime = end;
            } else {
                router.go(-1);
                toast({ message: 'Can not find reservation' });
            }
        } else {
            setInitialTime();
            editingEvent.value = null;
        }
        
        selectedDeskSpace.value = deskSpaces.value.find((d: any) => d.id === deskSpaceId);
    }

    async function bookDesk() {
        const deskSpaceId = selectedDeskSpace.value.id;
        const endTime = deskFiltersModel.value.endTime;
        const startTime = deskFiltersModel.value.startTime;
        const loadingInstance = await loading();
        const employeeId = store.employeeId;

        if (!store.employeeModel || !store.employeeModel.id) {
            store.employeeModel = await turfCore.customer?.employee?.get(employeeId);
        }
        
        bookingService.bookDesk(
            {
                deskSpaceId,
                timeZone: selectedDeskSpace.value.time_zone,
                startTime,
                endTime,
                customerId: customerId.value,
                employee: store.employeeModel,
            },
            function onDeskBookingCallback(error?: string): void {
                loadingInstance.dismiss();
                if (!error) {
                    gotoDeskBookingCompleted('create', deskSpaceId);
                } else {
                    console.error('Error booking desk:', error);
                    toast({ message: 'Unable to book' });
                }
            }
        );
    }

    async function editReservation() {
        const end = deskFiltersModel.value.endTime;
        const start = deskFiltersModel.value.startTime;

        const payload = { 
            start,
            end,
            timeZone: selectedDeskSpace.value.time_zone,
            customer: customer.value,
            event: {...editingEvent.value},
        };
        const loadingInstance = await loading();
    
        bookingService.editReservation(
            payload,
            function onDeskBookingCallback(error?: string): void {
                loadingInstance.dismiss();
                if (!error) {
                    toast({ message: 'Your reservation has been updated.', color: 'success' });
                } else {
                    console.error('Error on edit reservation:', error);
                    toast({ message: 'Unable to change your reservation' });
                }
            }
        );
    }

    async function _cancelReservation() {
        const payload = { 
            customer: customer.value,
            event: {...editingEvent.value},
        };
        const loadingInstance = await loading();
    
        bookingService.cancelReservation(
            payload,
            function onDeskBookingCallback(error?: string): void {
                loadingInstance.dismiss();
                if (!error) {
                    gotoDeskBookingCompleted('cancel');
                } else {
                    console.error('Error on cancel reservation:', error);
                    toast({ message: 'Unable to cancel your reservation' });
                }
            }
        );
    }

    async function cancelReservation() {
        await alert({
            header: 'Are you sure you want to cancel your reservation?',
            message: 'This will release the desk.',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                },
                {
                    text: 'Yes',
                    handler: () => {
                        _cancelReservation();
                    },
                },
            ],
        });
    }

    const prepareCalendarData = (events: any) => {
        if (events && events.length) {

            const eventsByDay = events.filter((event: any) => dayjs(event.start.toDate()).day() === dayjs().day());

            return eventsByDay.map((event: any) => {
                return {
                    'start': dayjs(event.start.toDate()).format('YYYY-MM-DD HH:mm'),
                    'end': dayjs(event.end.toDate()).format('YYYY-MM-DD HH:mm'),
                    'title': event.subject || 'Booked',
                };
            });
        }
    };

    const sortList = ({ sortDir, sortOption }: SortOptions) => {
        if (sortOption === 'status') {
            deskSpaces.value = deskSpaces.value.map((desk: Desk) => {
                desk['isAvailable'] = isAvailable(desk.id);
                return desk;
            });
        }

        if (sortDir === 'asc') {
            if (sortOption === 'name') {
                deskSpaces.value = orderBy(deskSpaces.value, ['name'], ['asc']);
            }
            if (sortOption === 'status') {
                deskSpaces.value = orderBy(deskSpaces.value, ['isAvailable'], ['desc']);
            }
        } else if (sortDir === 'desc') {
            if (sortOption === 'name') {
                deskSpaces.value = orderBy(deskSpaces.value, ['name'], ['desc']);
            }
            if (sortOption === 'status') {
                deskSpaces.value = orderBy(deskSpaces.value, ['isAvailable'], ['asc']);
            }
        }

    };

    return {
        bookDesk,
        buildlingsList,
        cancelReservation,
        deskFiltersModel,
        deskSpaceEvents,
        deskSpaces,
        deskSpacesFiltered,
        syncDeskEvents,
        editingEvent,
        editReservation,
        employeeReservations,
        floorsInSelectedBuilding,
        initialEndTime,
        initialStartTime,
        isAvailable,
        isValidTimeSlot,
        onFiltersTimeUpdate,
        prepareCalendarData,
        resetInitialTime,
        selectedDeskSpace,
        setDeskSpace,
        setInitialTime,
        sortList,
        resetSort,
        timeGapText,
        zonesInSelectedBuilding,
        allRoomsEvents,
        
    };
}