import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { collectionNames } from '../utils/firestoreCollections';
import { QuerySnapshot } from '@firebase/firestore-types';

dayjs.extend(duration);
dayjs.extend(utc);

const checkInLogConfig = {
    checkInMethod: '',
    checkedInAt: '',
    checkedOutAt: '',
    locationId: '',
    userId: '', // the checkin user id
    userType: '' // guest or employee
};

const timestamp = () => firebase.firestore.FieldValue.serverTimestamp();

const db = () => firebase.firestore();

// TODO: replace with turf core services later when functional with app (insertCheckinLog)
const confirmCheckIn = async (data: any) => {
    const customerCollectionRef = db().collection(collectionNames.CUSTOMERS);
    const utcDate = dayjs.utc(dayjs().toISOString()).toDate();
    const checkedInAt = firebase.firestore.Timestamp.fromDate(utcDate);
    const docData = {
        ...checkInLogConfig,
        checkedInAt,
        checkInMethod: data.checkInMethod,
        ...data
    };

    try {
        if (data.userType === 'employee') {
            docData['userType'] = 'employee';
        } else if (data.userType === 'guest') {
            docData['userType'] = 'guest';
        }

        return await customerCollectionRef
            .doc(data.customerId)
            .collection(collectionNames.CHECKIN_LOGS)
            .add(docData);

    } catch (err) {
        throw new Error('Unable to checkin');
    }
};

const searchQuery = async (searchText: any, userType: any, customerId: any) => {
    let employeeOrGuestCollection;

    if (userType === 'employee') {
        employeeOrGuestCollection = collectionNames.CHECKIN_EMPLOYEES;
    } else if (userType === 'guest') {
        employeeOrGuestCollection = collectionNames.CHECKIN_GUESTS;
    } else {
        throw new Error('Invalid user type');
    }
    return await db()
        .collection(collectionNames.CUSTOMERS)
        .doc(customerId)
        .collection(employeeOrGuestCollection)
        .where('searchFullName', 'array-contains', searchText.toLowerCase())
        .get()
        .then(data =>
            data.docs.map(doc => {
                const d = {
                    id: doc.id
                };
                const theData = { ...d, ...doc.data() };
                return theData;
            })
        );
};

const validateCheckinState = async (email: any, customerId: any) => {
    if (!email) return [];

    return await db()
        .collection(collectionNames.CUSTOMERS)
        .doc(customerId)
        .collection(collectionNames.CHECKIN_LOGS)
        .where('email', '==', email)
        .get()
        .then(data => data.docs.map(doc => doc.data()));
};

const insertActivityLog = async (customerId: any, payload: any) => {
    return await db()
        .collection(collectionNames.CUSTOMERS)
        .doc(customerId)
        .collection(collectionNames.CHECKIN_LOGS)
        .add(payload);
};

/**
 * watch a collection and call the callback function on snapshot
 * @param collectionPath 
 * @param onSnapshot 
 * @param options 
 * @returns 
 */
export function watchCollection<T>(
    collectionPath: string,
    onSnapshot: (x: QuerySnapshot<T>) => void,
    queryOptions?: FirestoreQuery
) {
    const colRef = db().collection(collectionPath);
    if (queryOptions?.where?.length === 3) {
        colRef.where(queryOptions.where[0], queryOptions.where[1], queryOptions.where[2]);
    }
    // TODO work on this ts type
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    return colRef.onSnapshot(onSnapshot);
}

export default {
    timestamp,
    db,
    confirmCheckIn,
    searchQuery,
    validateCheckinState,
    insertActivityLog
};