import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { ref } from 'vue';
import turfCore from '@skykit-dev/skykit-turf-core';

export const initialised = ref(false);

export default {
    install: (app: any) => {
        firebase.initializeApp({
            apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
            authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
            databaseURL: process.env.VUE_APP_FIREBASE_DB_URL,
            projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
            storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
            appId: process.env.VUE_APP_FIREBASE_APP_ID
        });

        if (location.hostname === 'localhost' && (process.env.VUE_APP_USE_FIREBASE_EMULATOR === 'true')) {
            console.debug('Connected to firebase emulator DB');
            firebase.firestore().settings({
                host: 'localhost:9000',
                ssl: false
            });
        }
        turfCore.init(firebase);

        app.config.globalProperties.$firestore = firebase.firestore();
        app.config.globalProperties.$auth = firebase.auth();

        firebase.auth().onAuthStateChanged((user: any) => {
            initialised.value = !!user;
            user && console.log('firebase initialized');
        });

        // automatically sign in, anonymous user don't expire
        firebase.auth().signInAnonymously();
    }
};