<template>
    <ion-page :style="bgImgStyles">
        <ion-header collapse="false">
            <ion-toolbar id="main-tool-bar">
                <ion-buttons slot="start">
                    <ion-button @click="goBack" v-if="$route.meta.showBackButton">
                        <ion-icon :icon="chevronBackOutline" size="large"/>
                    </ion-button>
                    <ion-button @click="goHome()" v-else-if="$route.meta.showHomeButton">
                        <ion-icon :icon="chevronBackOutline" size="large"/>
                    </ion-button>
                    <ion-button v-else>
                        <img :src="store.customerTheme?.logo || '/img/96.png'" class="toolbar-logo" alt="" />
                    </ion-button>
                </ion-buttons>
                <ion-title class="ion-text-left"> {{ title }}</ion-title>
                <ion-buttons slot="end">
                    <ion-button @click="openMenu" v-if="store.showSideMenu && store.customerId">
                        <ion-icon :icon="personCircle" style="font-size: 27px;" />
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <slot name="searchbar" />
        <ion-content :fullscreen="false">
            <slot name="body" />
        </ion-content>
        <ion-footer>
            <ion-toolbar class="ion-text-center base-layout">
                <ion-title size="small"
                    >Powered by Skykit® Turf v{{ version }}</ion-title
                >
            </ion-toolbar>
        </ion-footer>
    </ion-page>
</template>

<script>
import { computed, inject } from 'vue';
import { version } from '../../package.json';
import router from '../router';
import { chevronBackOutline, personCircle } from 'ionicons/icons';
import useNavigation from '@/composables/useNavigation';
import { menuController } from '@ionic/vue';
import { useMainStore } from '@/stores/main.store';
import { useRoute } from 'vue-router';

export default {
    name: 'Home',
    setup() {
        const { goHome } = useNavigation();
        const store = useMainStore();
        const route = useRoute();

        const appTitle = inject('appTitle');

        const title = computed(() => appTitle || route.meta?.appTitle || 'Turf');
        
        const bgImgStyles = computed(() => {
            if (store.customerTheme?.background_image) {
                return {
                    background: `url(${store.customerTheme.background_image}) no-repeat center center fixed`,
                    '-webkit-background-size': 'cover',
                    '-moz-background-size': 'cover',
                    '-o-background-size': 'cover',
                    'background-size': 'cover',
                };
            }
            return {};
        });

        function goBack() {
            // TODO: why? check this
            // if (spaceId) {
            //     return gotoQrScanner();
            // }
            router.back();
        }

        async function openMenu() {
            await menuController.open();
        }

        return {
            bgImgStyles,
            chevronBackOutline,
            goBack,
            goHome,
            openMenu,
            personCircle,
            store,
            title,
            version,
        };
    },
};
</script>
<style scoped lang="scss">
.toolbar-logo {
    $size: 22px;
    width: $size;
    height: $size;
    transform: translateX(7px);
}
</style>
