import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import { menuController } from '@ionic/vue';

/**
 * meta props:
 *      requireId: will redirect to 'QrScanner' if the URL doesn't have an ID. Default: true
 *      showBackButton: will show back button on nav bar. false will show logo with out link. Default: false
 *      appTitle: will set specific title for the route.
 */

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        alias: '/check-in',
        name: 'Home',
        component: () => import('../views/Home.vue'),
        meta: {
            requireId: true,
            appTitle: 'Turf',
        },
    },
    {
        path: '/scan',
        name: 'QrScanner',
        component: () => import('../views/QrScanner.vue'),
        meta: {
            requireId: false,
            appTitle: 'Turf',
        },
    },
    {
        path: '/employee',
        name: 'EmployeeHome',
        component: () => import('../views/EmployeeHome.vue'),
        meta: {
            appTitle: 'Turf',
        },
    },
    {
        path: '/visitorHostSelect',
        name: 'VisitorHostSelect',
        component: () => import('../views/VisitorHostSelect.vue'),
        meta: {
            showBackButton: true,
            appTitle: 'Check-In',
        },
    },
    {
        path: '/desk-booking',
        name: 'DeskBookingFilters',
        component: () => import('../views/DeskBookingFilters.vue'),
        meta: {
            requireId: false,
            appTitle: 'Book a desk',
        },
    },
    {
        path: '/desk-booking-search-results',
        name: 'DeskBookingSearch',
        component: () => import('../views/DeskBookingSearch.vue'),
        meta: {
            showHomeButton: true,
            appTitle: 'Book a desk',
        },
    },
    {
        path: '/desk-booking-details/:deskSpaceId',
        name: 'DeskBookingDetails',
        props: true,
        component: () => import('../views/DeskBookingDetails.vue'),
        meta: {
            showBackButton: true,
        },
    },
    {
        path: '/edit-reservation/:deskSpaceId/:eventId',
        name: 'EditReservation',
        props: true,
        component: () => import('../views/DeskBookingDetails.vue'),
        meta: {
            showBackButton: true,
        },
    },
    {
        path: '/desk-booking-booked',
        name: 'DeskBookedConfirmation',
        component: () => import('../views/DeskBookedConfirmation.vue'),
        meta: {
            appTitle: 'Turf',
        },
    },
    {
        path: '/my-reservations',
        name: 'MyReservations',
        component: () => import('../views/MyReservations.vue'),
        meta: {
            showBackButton: true,
            appTitle: 'My reservations',
        },
    },
    {
        path: '/confirmation',
        name: 'Confirmation',
        component: () => import('../views/Confirmation.vue'),
        meta: {
            appTitle: 'Turf',
        },
    },
    {
        path: '/:catchAll(.*)',
        name: 'catchAll',
        component: () => import('../views/QrScanner.vue'),
        meta: {
            requireId: false,
        }
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    menuController.close(); // close de menu on navigation

    // const requireId = 'requireId' in to.meta ? to.meta.requireId : true;

    // if (requireId && !to.query.id) {
    //     next({ name: 'QrScanner' });
    //     return;
    // }
    next();
});

export default router;
